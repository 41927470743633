export default {
    app: {
        title: 'resident portal',
        welcome: 'Welcome back',
        logout_menu: 'Logout',

        ok_action: 'ok',
        save_action: 'save',
        cancel_action: 'cancel',
        back_action: 'back',
        next_step_action: 'next step',
        retry_action: 're-try',
        delete_action: 'delete',

        content_loading: 'loading...',

        yes: 'yes',
        no: 'no',

        add: 'add',

        system: 'System',

        hello: 'hello!',
        sorry: 'sorry!',
        start: 'start',
    },

    accounting: {
        error_messages: {
            default: 'something went wrong',
        },
    },

    common: {
        main: 'main',
    },

    validation: {
        required: 'Required',
        email: 'Invalid email address',
        max_length: 'Must be {max} characters or less',
        min_length: 'Must be {min} characters or more',
        max_attachments_number: 'Must be {max} attachments or less',
        min_attachments_number: 'Must be {min} attachments or more',
        alphanumeric: 'Only alphanumeric characters',
        phone: 'Invalid phone number',
        us_phone: "Phone number must be 11 digits and start with '+' sign",
        no_spaces: 'Field cannot contain spaces',
        selected: 'At least one should be selected',
        min_value: 'Minimum value is {min}',
        max_value: 'Maximum value is {max}',
    },

    calendar: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        months_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days_short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },

    select: {
        select: 'select',
        reset_all: 'Reset all',
        apply: 'Apply',
        change: 'more',
        selected: '{count} {itemType} selected',
        search: 'search',
        no_results: 'No results.',
        item: 'item | items',
    },

    lease: {
        overview: 'lease overview',
        view_full: 'view full lease',
        block: {
            occupants: 'resident name',
            co_resident: 'co-resident',
            other: 'other occupants',
            pets: 'pets',
            expires: 'lease expires',
            rent_amount: 'rent amount',
            email: 'email address',
            phone: 'phone number',
        },
    },

    payments: {
        components: {
            account_summury: 'account summary',
            charges_list: 'charges',
            payments: 'payments',
        },
        pay_now: 'pay now',
        pay_now_is_not_allowed_description: 'Property accepts only Certified payments/money order',
        current_balance: 'current balance',
        previous_months_balance: 'Previous Month’s Balance',
        rent_and_services: 'Rent and services',
        charges_list: 'Charges List',
        list: {
            menu: 'Payments',
            title: 'payments',
            dummy_text: 'This is the payments listing.',
        },
        list_columns: {
            name: 'name',
            payer: 'payer',
            reference_no: 'reference no',
            date_posted: 'date posted',
            date_due: 'date due',
            payment_date: 'payment date',
            amount: 'amount',
            full_amount: 'full amount',
            other_amount: 'other amount',
            payment_amount: 'payment amount',
            balance: 'balance',
            status: 'status',
            notes: 'notes',
        },
        create: {
            menu: 'Pay Now',
            title: 'make payment',
            dummy_text: 'This is the make payment page.',
            edit_link: 'Edit payment details',
        },
        edit: {
            menu: 'Edit payment details',
            title: 'edit payment details',
            dummy_text: 'Edit payment details here',
        },
        messages: {
            payment_successful: 'Payment successful',
            transaction_declined: 'Transaction declined. Please try again.',
            transaction_canceled: 'Transaction canceled.',
            transaction_error: 'There was an error with your trasaction. Please try again.',
        },
    },

    auth: {
        username: 'email or phone number',
        password: 'password',
        new_password: 'new password',

        forgot_password: 'forgot password?',
        sign_in_action: 'sign in',
        sign_in_link: 'back to sign in form',

        logout_error: 'error signing out: ',

        forgot: {
            step1_title: 'forgot your password?',
            step1_message: 'Enter your Email below and we will send a message to reset your password',

            step2_title: 'reset your password',
            step2_message:
                "We've sent you an email with verification code to reset your password. Please enter your verification code and new password",

            reset_password_action: 'reset my password',

            success_message: 'Password has been reset',
            user_not_found_error: 'User with that email does not exist',
        },

        require_new_password: {
            title: 'new password required',
            change_action: 'change',
        },

        change_password: {
            title: 'Change password',
            old_password: 'old password',
            change_action: 'change',
            success: 'Password has been successfully changed',
        },
    },

    dashboard: {
        menu: 'Home',
        title: 'resident portal',
    },

    notifications: {
        title: 'Notifications',
        item: {
            title: 'Title',
            description: 'Description',
        },
    },

    profile: {
        menu: 'My profile',
        title: 'my profile',
        language: 'language',
        change_password: 'change password',
    },

    sreq: {
        list: {
            menu: 'Service Requests',
            title: 'service requests',
            status_groups: {
                opened: 'opened',
                to_be_approved: 'to be approved',
                resolved: 'resolved',
            },
            other_location: 'Other',
            is_empty: 'You don’t have any requests yet',
            autocompletion_notice:
                'In case you don’t Accept/Decline the requests in the list, they will be turned into Completed status and can’t be reopened.',
            approve_within: 'approve within',
            staff_approval_notice: 'can be turned into completed any time by community user',
        },

        create: {
            menu: 'New Service Request',
            title: 'new service request',
            submit_action: 'submit request',
            success: 'request has been successfully submitted',
        },

        update: {
            success: {
                general: 'request has been successfully updated',
                cancel: 'request has been successfully canceled',
                decline: 'the work done under the request has been declined',
                accept: 'the work done under the request has been successfully approved',
            },
        },

        details: {
            title: 'details',
        },

        time_in_out_logs: {
            title: 'time-in / time-out log',
            time_in: 'Time-in',
            time_out: 'Time-out',
            committed_by: 'committed by',
            empty_placeholder: 'The log is empty',
            not_specified_yet: 'Not specified yet',
        },

        feed: {
            title: 'chat',
            all: 'all',
            chat: 'chat',
            notes: 'notes',
            history: 'history',
            ordinary_note: 'ordinary note',
            cost_allocation_note: 'cost allocation note',
            recurring_payment: 'recurring payment',
            one_time_payment: 'one-time payment',
            start_date: 'start date',
            due_date: 'due date',
            total_cost: 'total cost',
            months_to_repay: '# of months to repay',
            unread_messages: 'unread messages',
            messaging_disabled: 'Sending notes and messages isn’t available as the request is resolved',
            composer_placeholder: {
                text: 'Type a new {0} or {1} here',
                0: 'message',
                1: 'note',
            },
            message: 'message',
            note: 'note',
        },

        status: {
            NEW: 'Received',
            ASSIGNED: 'Assigned',
            SCHEDULED: 'Scheduled',
            WIP: 'In Progress',
            PM_VALIDATION: 'Pending PM Validation',
            RESIDENT_VALIDATION: 'Pending Resident Validation',
            COMPLETED: 'Completed',
            CANCELED: 'Canceled',
            OPENED: 'Opened',
        },

        location: {
            title: 'where is the problem?',
            in_my_unit: 'in my unit',
            somewhere_else: 'somewhere else',
            building: 'building',
            unit: 'unit',
            apartment: 'apartment',
            other_location: 'other location',
            other_level: 'other level',
            other: 'other',
            location_level: 'location level {n}',
            level: 'level {n}',
        },

        fields: {
            description: 'please explain where and what are your service request',
            status: 'status',
            completed_by: 'completed by',
            canceled_by: 'canceled by',
            completion_date: 'completion date',
            cancellation_date: 'cancellation date',
            submitted_by: 'submitted by',
            submitted_date: 'submitted date',
            assigned_to: 'assigned to',
            scheduled_date: 'scheduled date',
            attachments: 'attachments',
            alarm_code: 'alarm code',
        },

        enter_permission: {
            title: 'enter permission',
            description:
                'do you grant permission for a service technician to enter your apartment during the scheduled appointment time whether you are present or not?',
            notice: 'Please crate your pets during the service appointment',
        },

        notifications: {
            preferred_title: 'preferred contact method',
            email: 'email:',
            email_value: 'email: {value}',
            text: 'text:',
            text_value: 'text: {value}',
            additional_title: 'additional notification method',
            additional_description: 'In addition to the Resident Portal, you also receive notifications to:',
            same_as_preferred: 'Same as Preferred contact method',
            events_title: 'event notifications',
            status_change: 'status change',
            new_chat_message: 'new chat message',
        },

        confirmation: {
            leave: {
                text: 'are you sure you want to cancel request creation? The data won’t be stored.',
                cancel_caption: 'go back to request',
            },

            cancel: {
                action: 'cancel request',
                title: 'Cancel request',
                text: 'Are you sure you want to cancel the request? You won’t be able to reopen it.',
                cancel_caption: 'Close',
                confirm_caption: 'Cancel request',
            },

            decline: {
                action: 'decline',
                title: 'Decline work',
                text: 'Are you sure you want to decline the work done under the request? The action can’t be undone.',
                cancel_caption: 'Cancel',
                confirm_caption: 'Decline work',
            },

            approve: {
                description: 'The request requires your confirmation for completion',
                action: 'accept',
                title: 'Accept work',
                text: 'Are you sure you want to accept the work done under the request? The action can’t be undone.',
                cancel_caption: 'Cancel',
                confirm_caption: 'Accept work',
            },
        },
    },

    messages: {
        list: {
            menu: 'Messages',
            title: 'notification center',
            is_empty: "You don't have any messages yet",
        },

        details: {
            title: 'details',
        },

        feed: {
            title: 'chat',
        },

        actions: {
            archive: 'archive',
            mark_as_read: 'mark as read',
            mark_as_unread: 'mark as unread',
            restore: 'restore',
        },
    },

    unit: {
        menu: 'My unit',
        title: 'my unit',
        dummy_text: 'This is the unit page.',
    },

    attachments: {
        attachments: 'attachments',
        upload_failed: 'Failed to upload attachments',
        see_all: 'see all ({num})',
        add_new: 'add new',
        field: {
            clear_all_new: 'clear all new files',
            new_files_count: '{num} new files',
            old_files_count: '{num} files',
        },
    },

    renewal: {
        menu: 'Lease Renewal Offer',
        title: 'Lease Renewal Offer',
    },

    ri: {
        title: 'Rentable Items',
        list: {
            title: 'All items',
            menu: 'Rentable Items',
            is_empty: 'There are no rentable items yet',
        },
        availability: {
            leased: 'Leased',
            available: '{num} available now',
        },
        deposit: {
            amount: 'deposit amount',
            deposit: 'deposit',
            price_per: 'price per {label}',
        },
        details: {
            title: 'Rentable item',
            working_hours: 'Working hours',
            closed: 'Closed',
            create_reservation: 'Make a new reservation',
        },
        reservations: {
            title: 'My reservations',
            create: {
                title: 'new reservation',
                to_be_paid: 'To be paid',
                save_reservation: 'Save reservation',
                search_by_item: 'Search by {riType} name',
                invalid_start_date: '{date} isn’t available any more. Please select new start day',
                invalid_items:
                    '{unavailable} from {total} {riType} selected isn’t available any more | {unavailable} from {total} {riType} selected aren’t available any more',
                invalid_start_datetime: '{datetime} isn’t available any more. Please select new start time',
                restricted_duration: 'Selected duration is restricted due to availability schedule',
                reduced_reservation_period:
                    'Please, note that your reservation period is less than {period} due to availability schedule of selected {selectedRiType}. {riType} should be returned before community closes during the last day. To return it later - set another reservation period',
            },
            list: {
                status_groups: {
                    actual: 'actual',
                    active: 'active',
                    completed: 'completed',
                    canceled: 'canceled',
                    pending: 'pending',
                },
                is_empty: 'You don’t have reservations yet',
            },
            status: {
                PENDING: 'Pending',
                ACTIVE: 'Active',
                COMPLETED: 'Completed',
                CANCELED: 'Canceled',
            },
            details: {
                title: 'reservation details',
            },
            confirmation: {
                create: {
                    text: 'are you sure you want to leave this screen without saving?',
                    cancel_caption: 'No, go back',
                    confirm_caption: 'Yes, leave',
                },
                cancel: {
                    action: 'Cancel reservation',
                    text: 'are you sure you want to cancel the reservation?',
                    cancel_caption: 'No, go back',
                    confirm_caption: 'Yes, cancel',
                },
            },
            fields: {
                item_name: '{riType} name',
                timeslot: 'rate per min. time slot',
                start_date: 'start date',
                end_date: 'end date',
                created_by: 'created by',
                canceled_by: 'canceled by',
                status: 'status',
                from: 'from',
                to: 'to',
                cancellation_date: 'cancellation date',
                number_of_ri: 'number of {unit} | number of {unit}s',
                duration_timeslot: 'duration ({timeslot})',
                duration: 'duration',
                available: 'available',
                not_specified: 'Not specified',
                items: 'Select {riType}',
                select_time: 'Select start time',
            },
            item_deleted: 'was deleted | were deleted',
            messages: {
                cancel: {
                    item_deleted: 'The reservation was canceled because the {riType} {deleted} by Community',
                    ri_deleted: 'The reservation was canceled because the rentable item was deleted by Community',
                },
                create: {
                    success: 'Reservation was successfully saved. | {reservationsCount} reservations were successfully saved.',
                    payment: 'To pay for it, please proceed to the',
                    resident_ledger: 'resident ledger',
                    info: 'You can start to select {riType}, then the calendar will display the corresponding available dates. And vice versa',
                },
            },
        },
        item: 'item | items',
        location: 'location | locations',
        periods: {
            hour: 'hour | hours',
            day: 'day | days',
            week: 'week | weeks',
            month: 'month | months',
        },
    },

    c2c: {
        welcome: 'Welcome to Quext Pass!',
        pass_starts_at: 'The pass will work after {date}',
        pass_failed: 'The pass is no longer available. Please, contact your host',
        something_went_wrong: 'Something went wrong',
        use_host_secret: 'use secret code',
        host_secret_notice: 'To use the Pass, please enter the secret code that the Host gave you.',
        use_verification_code: 'Enter verification code',
        otp_sent_notice: 'This code has been sent to your mobile number. Please enter it below.',
        host_secret: 'secret code',
        otp: 'verification code',
        verify: 'verify',
        resend_in: 'resend | resend in {count} sec | resend in {count} sec',
        otp_verification_failed_notice:
            'The code is wrong. You have no tries left. | The code is wrong. You have the last try. Try again | The code is wrong. You have {count} tries left. Try again',
        host_secret_verification_failed_notice: 'The host secret is wrong',
        your_pass: 'your pass',
        your_pass_from: 'your pass for {name}',
        access_to: 'access to',
        n_locations: '1 location | {count} locations',
        expiration_date: 'expiration date',
        due_date: 'due date',
        expires_in: 'expires in',
        limit_of_usage: 'limit of usage',
        host_left_message: 'the host left you a message:',
        hours_short: 'h',
        minutes_short: 'min',
        seconds_short: 'sec',
        tap_to_unlock: 'Tap to unlock',
        sending_request: 'We are sending request...',
        authorization_sent: 'Authorization sent',
        attempts_max_reached: 'The maximum amount of attempts in reached',
        no_verify_greeting: 'A resident has granted you access. Press the button to start managing your building access with ease.',
        send_otp: 'Send',
        mobile_phone: 'mobile phone',
        enter: 'enter',
        sms_code_header: 'We need to verify you',
        sms_code_notice: 'Please, enter your mobile number.',
    },
};
